<script>
export default {
  name: "ViewDetailsImage"
};
</script>

<template>
  <div class="view_details">
    <span>查看详情</span
    ><el-image
      :src="require('@/assets/common/viewDetailsImage.png')"
      fit="scale-down"
      class="view_details_image_warp"
      lazy
    />
  </div>
</template>

<style scoped lang="scss">
@import "./index.scss";

.view_details {
  color: $theme-red;
  cursor: pointer;
  font-size: 0.8rem;
}
.view_details:hover{
  transform: scale(1.08);
  transition: all 1s;
}
/deep/ {
  .el-image__inner {
    margin: 8px 0 0 10px;
    width: 20px;
  }
}
</style>

import request from "@/utils/request";

/**
 * 查询新闻列表
 * @param params
 * @returns {*}
 */
export const getNewsList = params => {
  return request({
    url: "/api/frontend/news/getNewsList",
    method: "get",
    params
  });
};

/**
 * 查询新闻详情
 * @param params
 * @returns {*}
 */
export const getNewsDetail = params => {
  return request({
    url: "/api/frontend/news/getNewsDetail",
    method: "get",
    params
  });
};

<script>
import NewsCard from "@/views/NewsCenter/components/NewsCard.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";

export default {
  name: "MediaReport",
  components: { NewsCard },
  computed: {
    NEWS_CENTER_TYPE() {
      return NEWS_CENTER_TYPE;
    }
  },
  data() {
    return {
      /**
       * 跳转到详情
       * @param id
       */
      jumpInfo(id) {
        if (this.$parent.checkNewsInfo) {
          this.$parent.checkNewsInfo(id);
        }
      }
    };
  }
};
</script>

<template>
  <div class="media_report_warp">
    <NewsCard :type="NEWS_CENTER_TYPE.mediaReport" />
  </div>
</template>

<style scoped lang="scss">
.media_report_warp {
  margin: 30px 0;
}
</style>

<script>
import ViewDetailsImage from "@/views/components/ViewDetailsImage.vue";
import NewsCard from "./components/NewsCard.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";

export default {
  name: "GroupNews",
  components: { ViewDetailsImage, NewsCard },
  computed: {
    NEWS_CENTER_TYPE() {
      return NEWS_CENTER_TYPE;
    }
  },
  data() {
    return {
      itemList: []
    };
  },
  mounted() {},
  methods: {
    /**
     * 查询新闻列表
     */
    getItemList(list) {
      this.itemList = list;
    },
    /**
     * 跳转到详情
     * @param id
     */
    jumpInfo(id) {
      if (this.$parent.checkNewsInfo) {
        this.$parent.checkNewsInfo(id);
      }
    }
  }
};
</script>

<template>
  <div class="group_warp">
    <div class="top">
      <div v-for="item in itemList" :key="item.key" class="top_item" @click="jumpInfo(item.id)">
        <el-image :src="item.default_img" fit="cover" lazy />
        <div class="info">
          <div class="title">{{ item.title }}</div>
          <div class="desc" v-html="item.sub_title || item.content"></div>
          <div class="time">
            <div class="time_hour">
              <div class="hour">{{ item.timeHour }}</div>
              <div>{{ item.time }}</div>
            </div>
            <div style="margin-top: 18px;">
              <ViewDetailsImage />
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewsCard :type="NEWS_CENTER_TYPE.news" @getListFun="getItemList" />
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.group_warp {
}
.group_warp .top {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;

  .top_item {
    width: 30%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    .el-image {
      height: 530px;
      width: 100%;
    }
  }
  .top_item .info {
    padding: 18px;
    margin-top: -4px;
    .time {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 0.8rem;
      line-height: 20px;
      .time_hour {
        color: rgba(0, 0, 0, 0.25);
      }
      .hour {
        font-size: 1.6rem;
      }
    }
    .title {
      color: #000000;
    }
    .desc {
      color: rgba(0, 0, 0, 0.5);
      margin-top: 10px;
      font-size: 0.8rem;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
      height: 96px;
    }
  }
  .top_item:hover {
    .info {
      background-color: $theme-orange;
    }
    .title,
    .desc {
      color: #ffffff;
    }
    .time {
      .time_hour {
        color: #ffffff;
      }
    }
    .view_details {
      color: #ffffff;
    }
  }
}
</style>

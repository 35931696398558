<script>
import NotData from "@/views/components/Not_data.vue";
import TitleButImage from "@/views/components/TitleButImage.vue";

export default {
  name: "CorporateCulture",
  components: { NotData, TitleButImage },
  data() {
    return {
      socialResponsibility: {
        key: 1,
        title: "企业使命",
        titleEn: "Corporate mission",
        desc:
          "作为国有企业，我们致力于推动艺术与金融的深度融合，为艺术市场提供稳定而专业的金融服务。 我们致力于传承和弘扬中国艺术文化，促进国内外艺术交流与合作。"
      },
      vision: {
        key: 2,
        title: "企业愿景",
        titleEn: "Corporate Vision",
        desc:
          "成为国内外知名的艺术金融服务领军企业，为艺术市场的发展贡献中国智慧和中国方案。构建一个安全、高效、创新的艺术金融生态系统，服务国家文化发展战略。"
      },
      mission: {
        key: 3,
        title: "社会责任",
        titleEn: "Social Responsibility",
        img: require("@/assets/news/corporate_01.png"),
        desc:
          "<span>艺术教育：通过举办讲座、展览等活动，普及艺术知识，提高公  众的艺术鉴赏能力。<br />公益支持：支持艺术公益项目，帮助艺术创作者和艺术机构解决资金问题。<br />环境保护：在业务活动中注重环境保护，推广绿色金融理念。</span>"
      },
      guidance: {
        key: 4,
        title: "党的引领",
        titleEn: "Call of the Party",
        img: require("@/assets/news/corporate_04.png"),
        desc:
          "<span>党的领导：坚持党的全面领导，确保企业发展方向和政策与国家战略保持一致。<br />党员先锋模范作用：党员在企业中发挥先锋模范作用，引领企业文化建设。</span>"
      }
    };
  }
};
</script>

<template>
  <div class="corporate_culture_warp">
    <div class="top">
      <div class="comm socialResponsibility">
        <div>
          <div class="titleEn_comm">{{ socialResponsibility.titleEn }}</div>
          <div class="title_comm">{{ socialResponsibility.title }}</div>
          <div class="desc_comm">{{ socialResponsibility.desc }}</div>
        </div>
      </div>
      <div class="comm vision">
        <div>
          <div class="titleEn_comm">{{ vision.titleEn }}</div>
          <div class="title_comm">{{ vision.title }}</div>
          <div class="desc_comm">{{ vision.desc }}</div>
        </div>
      </div>
    </div>
    <div class="bottom_warp">
      <div style="width: 100%">
        <div class="titleEn_comm">{{ mission.titleEn }}</div>
        <div class="title_comm">{{ mission.title }}</div>
        <div class="desc_comm" v-html="mission.desc"></div>
      </div>
    </div>
    <div class="guidance_warp">
      <div style="width: 100%">
        <div class="titleEn_comm">{{ guidance.titleEn }}</div>
        <div class="title_comm">{{ guidance.title }}</div>
        <div class="desc_comm" v-html="guidance.desc"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.corporate_culture_warp {
}
.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.comm {
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat;
  height: 246px;
  width: 48%;
  display: flex;
  align-items: center; /* 垂直居中 */
  padding: 0px 30px;
}
.socialResponsibility {
  background-image: url("../../assets/news/corporate_02.png");
}
.vision {
  background-image: url("../../assets/news/corporate_03.png");
}
.bottom_warp {
  background-image: url("../../assets/news/corporate_01.png"); /* 替换为你的图片路径 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 416px;
  display: flex;
  align-items: center; /* 垂直居中 */
  padding: 0px 30px;
  width: 100%; /* 或者你需要的宽度 */
  margin-bottom: 30px;
}
.guidance_warp {
  background-image: url("../../assets/news/corporate_04.png"); /* 替换为你的图片路径 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 246px;
  display: flex;
  align-items: center; /* 垂直居中 */
  padding: 0px 30px;
  width: 100%; /* 或者你需要的宽度 */
  margin-bottom: 30px;
}
.titleEn_comm {
  color: rgba(0, 0, 0, 0.25);
  font-size: 0.8rem;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.title_comm {
  color: $theme-red;
  font-size: 1.4rem;
  margin-bottom: 22px;
}
.desc_comm {
  color: #000000;
  font-size: 0.8rem;
  width: 50%;
  line-height: 22px;
}
</style>

<template>
  <div>
    <el-skeleton :rows="6" animated :loading="loading">
      <ul
        class="infinite-list"
        v-infinite-scroll="getNewsList"
        style="overflow:auto;padding-left: 0"
      >
        <div class="video_warp" v-if="itemList.length !== 0">
          <div v-for="item in itemList" :key="item.key" class="video_item">
            <div class="image_warp" v-if="item.att_type === 1">
              <video
                :src="item.att_data"
                controls
                :ref="`attDataVideo${item.id}`"
                v-on:mouseover="playVideo(item.id)"
                v-on:mouseleave="pauseVideo(item.id)"
              ></video>
            </div>
            <div class="image_warp" v-else>
              <el-image :src="item.default_img" fit="cover" lazy />
              <div v-if="item.att_type === 2" class="file_resource">
                文件资料
              </div>
            </div>
            <div class="info">
              <div class="time">{{ formatApiDate(item.created_at) }}</div>
              <div class="title">{{ item.title }}</div>
              <div class="desc" v-html="item.sub_title || item.content"></div>
              <div class="upload">
                <div class="but" @click="uploadFile(item.att_data)">
                  {{ item.att_type === 1 ? "下载视频" : "下载文件" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Not_data v-else />
      </ul>
      <LearnMore v-if="this.last_page !== this.current_page" />
    </el-skeleton>
  </div>
</template>

<script>
import LearnMore from "@/views/components/LearnMore.vue";
import { getNewsList } from "@/api/newsCenter";
import { NEWS_CENTER_TYPE } from "@/utils/enum";
import { formatApiDate, getFileName } from "@/utils/utils";
import Not_data from "@/views/components/Not_data.vue";

export default {
  name: "VideoData",
  components: { Not_data, LearnMore },
  data() {
    return {
      itemList: [],
      loading: false,
      last_page: 0, // 最后一页
      current_page: 0 // 当前页
    };
  },
  computed: {
    formatApiDate() {
      return formatApiDate;
    }
  },
  methods: {
    /**
     * 查询新闻
     */
    getNewsList() {
      if (this.current_page === this.last_page && this.current_page !== 0) {
        return;
      }
      this.loading = true;
      const params = {
        type: NEWS_CENTER_TYPE.videoData,
        page: this.current_page + 1,
        limit: 10
      };
      getNewsList(params)
        .then(resp => {
          const data = resp.data;
          const itemData = data.data;
          const itemList = [];
          if (itemData && itemData.length !== 0) {
            itemData.map(item => {
              itemList.push({
                ...item
              });
            });
          }
          // 新闻这一栏单独处理
          this.itemList = this.itemList.concat(itemList);
          this.last_page = data.last_page;
          this.current_page = data.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 下载文件
     * @param att_data
     */
    uploadFile(att_data) {
      if (att_data) {
        const link = document.createElement("a");
        link.href = att_data;
        link.setAttribute("download", getFileName(att_data));
        link.click();
      }
    },
    playVideo(id) {
      const video = this.$refs["attDataVideo" + id];
      if (video) {
        video[0].play();
      }
    },
    pauseVideo(id) {
      const video = this.$refs["attDataVideo" + id];
      if (video) {
        video[0].pause();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../index.scss";

.video_warp {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0 0;
  .image_warp {
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 252px;
    .file_resource {
      position: absolute;
      top: 64%;
      left: 41%;
      color: #000000; /* 文本颜色 */
      font-size: 20px; /* 文本大小 */
      text-align: center;
    }
    video {
      width: 100%;
      cursor: pointer;
    }
  }
  .video_item {
    width: 30%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
    margin-right: 38px;
    flex: 0 0 auto;
    .file_resource {
      text-align: center;
    }
  }
  .video_item .info {
    padding: 20px 20px 40px;
    .time {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.8rem;
    }
    .title {
      color: #000000;
      line-height: 50px;
    }
    .desc {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.8rem;
      height: 100px;
      overflow-y: auto;
      line-height: 20px;
    }
    .upload {
      float: right;
      margin: 20px 0;
      .but {
        border: 1px solid $theme-red;
        color: $theme-red;
        width: 80px;
        text-align: center;
        height: 30px;
        line-height: 28px;
        font-size: 0.8rem;
        border-radius: 4px;
        cursor: pointer;
      }
      .but:hover {
        background-color: $theme-red;
        color: #ffffff;
      }
    }
  }
  .el-image {
    height: 257px;
    width: 100%;
  }
}
</style>

<script>
import ViewDetailsImage from "@/views/components/ViewDetailsImage.vue";
import Not_data from "@/views/components/Not_data.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";
import { getNewsList } from "@/api/newsCenter";
import moment from "moment";
import LearnMore from "@/views/components/LearnMore.vue";

export default {
  name: "NewsCard",
  components: { LearnMore, Not_data, ViewDetailsImage },
  props: {
    type: NEWS_CENTER_TYPE,
    getListFun: Function
  },
  watch: {},
  data() {
    return {
      itemList: [],
      listParams: {
        pageNum: 1,
        pageSize: 10,
        loading: false,
        error: false,
        finished: false
      }
    };
  },
  computed: {
    disabled() {
      console.log(this.listParams.loading || this.listParams.finished);
      return this.listParams.loading || this.listParams.finished;
    }
  },
  created() {
    this.getNewsList();
  },
  mounted() {},
  methods: {
    onLoad() {
      console.log("this.listParams.finished", this.listParams.finished);
      this.listParams.loading = true;
      if (this.listParams.finished === false) {
        this.listParams.pageNum++;
        this.getNewsList();
      }
    },
    /**
     * 查询新闻列表
     */
    getNewsList() {
      let that = this;
      this.loading = true;
      const params = {
        type: this.type,
        page: that.listParams.pageNum,
        limit: that.listParams.pageSize
      };
      getNewsList(params)
        .then(resp => {
          const data = resp.data;
          const itemData = data.data;
          const itemList = []; // 封装后的数据
          if (itemData && itemData.length !== 0) {
            itemData.map(item => {
              itemList.push({
                ...item,
                time: moment(item.created_at).format("YYYY-MM"),
                timeHour: moment(item.created_at).format("DD")
              });
            });
          }

          // 新闻这一栏单独处理
          if (this.type === NEWS_CENTER_TYPE.news) {
            // 第一页
            if (data.current_page === 1) {
              this.$emit("getListFun", itemList.slice(0, 3));
              this.itemList = itemList.slice(3);
            } else {
              // 其他的翻滚页
              this.itemList = this.itemList.concat(itemList);
            }
          } else {
            this.itemList = this.itemList.concat(itemList);
          }
          console.log("itemList", itemList);
          console.log("this.itemList", this.itemList);
          // 处理分页
          if (itemList.length > 0) {
            that.listParams.loading = false;
            if (itemList < that.listParams.pageSize) {
              that.listParams.finished = true;
            }
          } else {
            that.listParams.loading = false;
            that.listParams.finished = true;
          }

          that.listParams.loading = false;
        })
        .catch(function() {
          that.listParams.error = true;
          that.listParams.loading = false;
        })
        .finally(() => {
          this.listParams.loading = false;
        });
    },

    getInfo(id) {
      if (this.$parent.jumpInfo) {
        this.$parent.jumpInfo(id);
      }
    }
  }
};
</script>

<template>
  <div class="container">
    <el-skeleton :rows="6" animated :loading="listParams.loading">
      <div
        class="infinite-list"
        v-infinite-scroll="onLoad"
        :infinite-scroll-disabled="disabled"
        :infinite-scroll-immediate="false"
      >
        <template v-if="itemList.length !== 0">
          <div
            v-for="item in itemList"
            :key="item.key"
            class="infinite-list-item card_item"
            @click="getInfo(item.id)"
          >
            <div class="time_hour">
              <div class="hour">{{ item.timeHour }}</div>
              <div>{{ item.time }}</div>
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="desc" v-html="item.sub_title || item.content"></div>
              <div class="save_info">
                <ViewDetailsImage />
              </div>
            </div>
            <div>
              <el-image :src="item.default_img" fit="cover" lazy />
            </div>
          </div>
        </template>
        <Not_data v-else />
      </div>
      <LearnMore v-if="!listParams.finished" />
    </el-skeleton>
  </div>
</template>

<style scoped lang="scss">
.container {
  flex-direction: row;
  height: 100%;
  padding: 10px;
}
.infinite-list {
  flex-shrink: 0;
  background-color: white;
  overflow: auto;
}
.card_item {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  .el-image {
    height: 236px;
    width: 600px;
  }
}
.info {
  width: 40%;
  .title {
    color: #000000;
  }
  .desc {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    font-size: 0.8rem;
    line-height: 22px;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 150px;
    overflow: hidden; /* 确保内容超出元素时会被裁剪 */
    white-space: nowrap; /* 确保文本不会换行 */
  }
  .save_info {
    margin-top: 20%;
    float: right;
  }
}
.time_hour {
  color: rgba(0, 0, 0, 0.25);
}
.hour {
  font-size: 1.6rem;
}
</style>

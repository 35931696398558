<script>
import NotData from "@/views/components/Not_data.vue";

export default {
  name: "Aptitude",
  components: { NotData }
};
</script>

<template>
  <NotData />
</template>

<style scoped lang="scss"></style>

<script>
export default {
  name: "TitleButImage",
  props: {
    title: String
  }
};
</script>

<template>
  <div class="title_div_warp">
    <div class="title">{{ title }}</div>
    <div class="title_but_warp" />
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";
.title_div_warp {
  text-align: center;
  width: 130px;
}
.title_div_warp .title {
  height: 50px;
  font-weight: 400;
  font-size: 1.6rem;
  color: $theme-red;
  line-height: 54px;
  font-style: normal;
}
.title_div_warp .title_but_warp {
  background: url("../../assets/home/business_bg.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  height: 8px;
}
</style>

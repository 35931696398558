<template>
  <div class="group-news-info">
    <el-skeleton :rows="6" animated :loading="loading">
      <div class="group-news-info-title">
        {{ newsInfo && Object.keys(newsInfo).length !== 0 && newsInfo.title }}
      </div>
      <div class="group-news-info-create_time">
        发布于：{{
          moment(
            newsInfo &&
              Object.keys(newsInfo).length !== 0 &&
              newsInfo.created_at
          ).format("YYYY-MM-DD hh:mm:ss")
        }}
      </div>
      <div
        class="group-news-info-content"
        v-html="
          newsInfo && Object.keys(newsInfo).length !== 0 && newsInfo.content
        "
      ></div>
    </el-skeleton>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/newsCenter";
import moment from "moment";

export default {
  name: "GroupNewsInfo",
  computed: {
    moment() {
      return moment;
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newId: this.id,
      loading: false,
      newsInfo: {} // 新闻
    };
  },
  mounted() {
    this.loading = true;
    const params = {
      id: this.id
    };
    getNewsDetail(params)
      .then(resp => {
        let data = {};
        if (resp.code === 0) {
          data = resp.data;
        }
        this.newsInfo = data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>

<style scoped lang="scss">
.group-news-info {
  padding: 40px 60px;
  .group-news-info-title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 34px;
  }
  .group-news-info-create_time {
    font-size: 16px;
    color: #8c939d;
    text-align: right;
    margin: 20px 0;
  }
  .group-news-info-content {
    font-size: 18px;
    line-height: 26px;
  }
}
</style>
